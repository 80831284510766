import React from 'react'
import logoLgt from '../assets/logo-lgt.svg'
import heroImage from '../assets/CourierWorld-hero.svg'
import LogosTicker from './LogosTicker'
import DHL from '../assets/CourierLogos/DHL.png'
import EDD from '../assets/delivery-date.svg'
import CollectionDate from '../assets/collection-icon.svg'
import Warranty from '../assets/warranty.svg'
import gps from '../assets/gps.svg'
import weight from '../assets/weight.svg'
import quantity from '../assets/quantity.svg'
import box from '../assets/box.svg'
import height from '../assets/height.svg'
import length from '../assets/length.svg'
import width from '../assets/width.svg'
import arrow from '../assets/arrow.svg'
import dBox from '../assets/dimension-box.svg'
import CalQuote from './CalQuote'
import HeroAnimation from './HeroAnimation'
import compare from '../assets/compare.svg'
import booking from '../assets/booking.svg'
import delivery from '../assets/delivery.svg'
import dVan from '../assets/delivery-van.svg'




function Home() {
    return (
        <>
            <HeroAnimation />
            <div className="container-fluid hero-section">
                <div className="hero-contents text-center mt-5">
                    <h1 className=''>Compare, Book, Delivered</h1>
                    <p>One Platform, Every Carrier -- Compare Quotes, Book Shipments and Let Us Take Care of the Entire Process, From Pickup to Delivery</p>
                    <a href="/CalQuote" className='btn btn-rnd'>Book Now</a>
                </div>
                {/* <div className='hero-image-container'>
                <img src={heroImage} alt="WorldCourier Hero" />
            </div> */}
            </div>
            <LogosTicker />
            <div className="container text-center home-cards  mt-6">
                <h2 className='mb-5 '>How Courier World Works</h2>
                <div className="box-containers d-flex justify-content-around flex-wrap">
                    <div className="card center-center mt-3" style={{ width: "18rem" }}>
                        <img src={compare} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Compare</h5>
                        </div>
                    </div>
                    <div className="card center-center mt-3" style={{ width: "18rem" }}>
                        <img src={booking} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Booking</h5>
                        </div>
                    </div>
                    <div className="card center-center mt-3" style={{ width: "18rem" }}>
                        <img src={delivery} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Delivery</h5>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <h3>Get 30+ Quotes from different</h3>
                        </div>
                        <div className="col-lg-6">
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container-fluid home-cal-quote-container py-5 mt-6">
                <CalQuote />
            </div>

            <div className="container-our-promises mt-6">
                <h2 className='mb-5 text-center '>Our Promises</h2>
                <div className="box-containers d-flex justify-content-around flex-wrap">
                    <div className="card mt-3" style={{ width: "18rem" }}>
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <span class="prom-num">01</span>
                                <h5 className="card-title mb-0">On Time Delivery</h5>
                            </div>
                            <p>Get your parcels Delivered on time, Every Time</p>
                        </div>
                    </div>
                    <div className="card mt-3" style={{ width: "18rem" }}>
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <span class="prom-num">02</span>
                                <h5 className="card-title mb-0">Secure Payment</h5>
                            </div>
                            <p>we care for security, Pay online with peace of mind</p>
                        </div>
                    </div>
                    <div className="card mt-3" style={{ width: "18rem" }}>
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <span class="prom-num">03</span>
                                <h5 className="card-title mb-0">Support Available</h5>
                            </div>
                            <p>Customer support Available in any case</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="booknow-cta mt-6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mb-5 center-center">
                            <div>
                                <h3 className='display-5'>What are you waiting for?</h3>
                                <p>Deliver your parcels conviniently & Faster</p>
                                <button className="btn btn-smth">Book Now</button>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-5">
                            <div className="dvan-container">
                                <img src={dVan} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <footer class="py-5 p-relative">
                    <div class="container py-5">
                        <div class="row">
                            <div class="col-lg-3">
                                <div class="footer-logo d-flex">
                                    <img src={logoLgt} alt="Git-Go Technology Logo"/>
                                </div>
                                <div class="footer-company-name mt-3">
                                    <p class="company-txt lead fw-300">Compare,Book,Delivered</p>
                                </div>
                                {/* <div class="social-links d-flex mb-5 mt-3">
                                    <a href="https://www.instagram.com/git_gotech/?igshid=YmMyMTA2M2Y=" target="_blank">
                                        <div class="social-icon mr-5">
                                            <img src="Assets/Icons/instagram.svg" alt="Instagram Git-Go Technology">
                                        </div>
                                    </a>
                                    <a href="https://www.facebook.com/people/Git-GoTech/100082478561094/" target="_blank">
                                        <div class="social-icon mr-5">
                                            <img src="Assets/Icons/facebook.svg" alt="Facebook Git-Go Technology">
                                        </div>
                                    </a>
                                    <a href="https://www.tiktok.com/@git_go_technology" target="_blank">
                                        <div class="social-icon mr-5">
                                            <img src="Assets/Icons/tiktok-white-logo.svg" alt="tiktok Git-Go Technology">
                                        </div>
                                    </a>

                                </div> */}
                            </div>
                            <div class="col-lg-3">
                                <div class="links">
                                    <p class="h4 mb-3">Company</p>
                                    <a href="about.php" class="">About Us</a>
                                    <a href="" class="mt-2 mb-5">FAQ's</a>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="links">
                                    <p class="h4 mb-3">Help</p>
                                    <a href="contact.php" class="">Contact Us</a>
                                     <a href="Pricing.php" class="mt-2">Contact@CourierLink.com.au</a>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <div class="links">
                                    <p class="h4 mb-3">Legal</p>
                                    <a href="privacy-policy.php" class="">Privacy policy</a>
                                    <a href="Terms-and-Conditions.php" class="mt-2">Terms and Conditions</a>
                                    <a href="refund-policy.php" class="mt-2">Refund Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </footer>

                <div class="copyright-text-container py-4 text-center">
                   <p class="mb-0">Copyright©CourierLink 2024</p>
                </div>
            </div>


        </>
    )
}

export default Home
